import React from "react";
import { useTranslation } from "react-i18next";

const TrialAborted = () => {
  const { t } = useTranslation();

  return (
    <div id="trial-finished">
      <div className="heading">{t("This exam is aborted")}</div>
    </div>
  );
};

export default TrialAborted;
