NotFoundHint: "Please click logout if an exam was taken by a different account on your computer."
achievements page: achievements page
finishedPreMessageWithSub: "The result will be available at "
finishedPostMessageWithSub: " after marking."
finishedMessageWithoutSub: "The result will be at user page if available after marking."
finishedNoteWithoutSub: ""
instantDisclosureMessage: The result is also available at this page.
instantDisclosureNote: Please visit achievements page for details.
instantDisclosureButton: Show Result
hideButton: Hide Result
reInstantDisclosureMessage: Achievements can be redisplayed.
reInstantDisclosureButton: Redisplay Result
