import { queryFn as defaultQueryFn } from "$scripts/utils/queryClient";
import { useQuery } from "@tanstack/react-query";
import { Button, message } from "antd";
import Cookies from "js-cookie";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ResultResponse {
  achievement: { success?: boolean; score?: number; cert: { name: string } };
}

const InstantDisclosure: FC<{ trialId: string }> = ({ trialId }) => {
  const { t } = useTranslation();

  const [resultEnabled, setResultEnabled] = useState(false);
  const {
    data: result,
    error: resultError,
    fetchStatus: resultFetchStatus,
    refetch: refetchResult,
  } = useQuery<ResultResponse, Error>([`/api/result/${trialId}`], {
    enabled: resultEnabled,
    retry: 3,
    queryFn: async (context) => {
      const result = await defaultQueryFn<ResultResponse>(context);
      if (result.achievement === undefined) {
        throw Error("Operation not allowed");
      } else if (result.achievement === null) {
        throw Error("Result is not available");
      }
      return result;
    },
  });

  useEffect(() => {
    if (resultError == null) {
      return;
    }
    message.error(resultError.message);
  }, [resultError]);

  const hasTrialSignature = Cookies.get("trialSignature") != null;

  return (
    <div className="instant-disclosure">
      {result == null ? (
        <div className="prompt">
          <div className="message">{t("instantDisclosureMessage")}</div>
          <Button
            shape="round"
            disabled={!hasTrialSignature}
            loading={resultFetchStatus !== "idle"}
            onClick={() => {
              if (resultEnabled) {
                refetchResult();
              } else {
                setResultEnabled(true);
              }
            }}
          >
            {t("instantDisclosureButton")}
          </Button>
        </div>
      ) : resultEnabled ? (
        <div className="content">
          <div className="cert">{result.achievement.cert.name}</div>
          <div className="achievement">
            {result.achievement.success != null ? (
              <div className="result">
                <div>{t("Result")}</div>
                <div className="val">
                  {result.achievement.success ? t("Success") : t("Fail")}
                </div>
              </div>
            ) : null}
            {result.achievement.score != null ? (
              <div className="score">
                <div>{t("Score")}</div>
                <div className="val">{result.achievement.score}</div>
              </div>
            ) : null}
          </div>
          <div className="note">{t("instantDisclosureNote")}</div>
          <Button
            shape="round"
            disabled={!hasTrialSignature}
            loading={resultFetchStatus !== "idle"}
            onClick={() => {
              setResultEnabled(false);
            }}
          >
            {t("hideButton")}
          </Button>
        </div>
      ) : (
        <div className="prompt">
          <div className="message">{t("reInstantDisclosureMessage")}</div>
          <Button
            shape="round"
            disabled={!hasTrialSignature}
            loading={resultFetchStatus !== "idle"}
            onClick={() => {
              setResultEnabled(true);
            }}
          >
            {t("reInstantDisclosureButton")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default InstantDisclosure;
