import { ChatMessage } from "$types/ChatMessage";
import { db } from "$utils/firebase";
import { CloseOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Input, Tooltip } from "antd";
import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";

export interface ChatActions {
  setChatVisible: Dispatch<SetStateAction<boolean>>;
}

interface Props {
  trialId: string;
  activated: boolean;
  actionsRef?: MutableRefObject<ChatActions | undefined>;
  onMessage?: (messages: ChatMessage[]) => void;
  onVisibilityChanged?: (visible: boolean) => void;
}

const Chat = ({
  trialId,
  activated,
  actionsRef,
  onMessage,
  onVisibilityChanged,
}: Props) => {
  const { t } = useTranslation();

  const [isChatVisible, setChatVisible] = useState<boolean>(false);

  const [messages, setMessages] = useState<ChatMessage[]>([]);

  const messagesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!activated) {
      return;
    }
    const unsubscribe = onSnapshot(
      query(
        collection(db, "trials", trialId, "messages"),
        orderBy("createdAt", "asc")
      ),
      (snapshot) => {
        const _messages: ChatMessage[] = [];
        snapshot.forEach((result) => {
          const raw = result.data();
          _messages.push({
            id: result.id,
            ...raw,
            createdAt: raw.createdAt
              ? new Date(raw.createdAt.seconds * 1000)
              : undefined,
          } as ChatMessage);
        });
        setMessages(_messages);
      }
    );
    return unsubscribe;
  }, [trialId, activated]);

  useEffect(() => {
    const element = messagesRef.current;
    if (element != null) {
      element.scrollTop = element.scrollHeight - element.clientHeight;
    }
    onMessage?.(messages);
  }, [messages]);

  useEffect(() => {
    onVisibilityChanged?.(isChatVisible);
  }, [isChatVisible]);

  useEffect(() => {
    if (actionsRef == null) {
      return;
    }
    actionsRef.current = {
      setChatVisible,
    };
  }, [actionsRef]);

  const [text, setText] = useState("");

  const [isSendingMessage, setIsSendingMessage] = useState(false);

  const sendMessage = () => {
    if (text.length === 0) {
      return;
    }

    (async () => {
      setIsSendingMessage(true);
      addDoc(collection(db, "trials", trialId, "messages"), {
        text,
        isUser: true,
        createdAt: serverTimestamp(),
      });
      setText("");
      setIsSendingMessage(false);
    })();
  };

  return (
    <div className="chat-container">
      <Draggable handle=".handle" bounds="parent">
        <div
          className="chat-window"
          style={{ visibility: isChatVisible ? "visible" : "hidden" }}
        >
          <div className="header-bar handle">
            <span>{t("Call Proctor")}</span>
            <Tooltip title={t("Close")}>
              <CloseOutlined
                className="close"
                style={{ display: "inline", cursor: "pointer" }}
                onClick={() => {
                  setChatVisible(false);
                }}
                onTouchEnd={() => {
                  setChatVisible(false);
                }}
              />
            </Tooltip>
          </div>
          <div className="messages" ref={messagesRef}>
            {messages.map((message) => (
              <div
                className={`message ${message.isUser ? "me" : "other"}`}
                key={message.id}
              >
                <div className="content">
                  <div className="text">{message.text}</div>
                </div>
                <div className="at">
                  {!message.createdAt
                    ? t("Sending")
                    : message.createdAt.toLocaleString()}
                </div>
              </div>
            ))}
          </div>
          <div className="footer-bar">
            <Input.TextArea
              className="text"
              autoSize={{ minRows: 1 }}
              placeholder={t("Write a message")}
              value={text}
              onChange={({ target: { value } }) => {
                setText(value);
              }}
            />
            <Tooltip title={t("Send")}>
              <Button
                className="send"
                type="text"
                shape="circle"
                disabled={!activated || isSendingMessage || text.length === 0}
                icon={<SendOutlined />}
                onClick={() => {
                  sendMessage();
                }}
              />
            </Tooltip>
          </div>
        </div>
      </Draggable>
    </div>
  );
};

export default Chat;
