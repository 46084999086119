import {
  QueryClient,
  QueryFunctionContext,
  QueryKey,
} from "@tanstack/react-query";

export const queryFn = async <
  T = unknown,
  TQueryKey extends QueryKey = QueryKey
>({
  queryKey,
  signal,
}: QueryFunctionContext<TQueryKey>): Promise<T> => {
  const controller = new AbortController();
  if (signal?.aborted) {
    controller.abort();
  } else {
    setTimeout(() => {
      controller.abort();
    }, 90 * 1000);
    signal?.addEventListener("abort", () => {
      controller.abort();
    });
  }
  const res = await fetch(queryKey[0] as string, {
    method: "GET",
    cache: "no-cache",
    signal: controller.signal,
  });
  if (!res.ok) {
    throw Error("Response was not ok");
  }
  return res.headers.get("content-type")?.includes("application/json")
    ? res.json()
    : res.text();
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn,
      retry: 1,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
    mutations: {
      networkMode: "always",
    },
  },
});
