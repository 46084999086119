const getMediaStreams = () => ({
  cameras: [
    ...document.querySelectorAll<HTMLVideoElement>("#camera-preview video"),
  ].map((element) => element.srcObject),
  screens: [
    ...document.querySelectorAll<HTMLVideoElement>("#screen-preview video"),
  ].map((element) => element.srcObject),
  incomings: [
    ...document.querySelectorAll<HTMLVideoElement>("#video-incoming video"),
  ].map((element) => element.srcObject),
});

(window as any).__excert = {
  getMediaStreams,
};
