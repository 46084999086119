import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

export const firebaseApp = initializeApp(
  JSON.parse(process.env.FIREBASE_CONFIG!)
);

export const db = getFirestore(firebaseApp);

export const auth = getAuth(firebaseApp);
