import Cookies from "js-cookie";
import React, {
  FC,
  MutableRefObject,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Button, message } from "antd";

const Tc = () => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => new URLSearchParams(search), []);

  const requirePin = searchParams.has("pin");

  useEffect(() => {
    const tcks = searchParams.getAll("tck");
    if (tcks.length !== 0) {
      Cookies.set("tcks", JSON.stringify(tcks));
    }
    history.replace(`${pathname}${requirePin ? "?pin" : ""}`);
  }, [searchParams]);

  const uuidInputActionsRef = useRef<UuidInputActions>();
  const pinInputActionsRef = useRef<PinInputActions>();

  return (
    <div id="tc">
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');
      </style>
      <div className="center">
        {Cookies.get("tcks") == null ? (
          <div>
            <p style={{ color: "red" }}>WARN: TCK missing</p>
            <div className="space" />
          </div>
        ) : null}
        <div>
          <p>{t("Input your test ID")}</p>
        </div>
        <UuidInput actionsRef={uuidInputActionsRef} />
        <div className="space" />
        {!requirePin ? null : (
          <>
            <p>{t("Input your PIN")}</p>
            <PinInput actionsRef={pinInputActionsRef} />
            <div className="space" />
          </>
        )}
        <Button
          type="primary"
          shape="round"
          size="large"
          onClick={async () => {
            const uuid = uuidInputActionsRef.current?.validateAndGetUuid();
            const pin = pinInputActionsRef.current?.validateAndGetPin();
            if (uuid == null || (requirePin && pin == null)) {
              return;
            }
            if (requirePin) {
              try {
                const res = await fetch(`/api/pin/${uuid}/${pin}`, {
                  method: "GET",
                });
                const { isValid } = await res.json();
                if (!isValid) {
                  message.error(t("Test ID or PIN is not correct"));
                  return;
                }
              } catch (e) {
                message.error(t("Fail to call API"));
                return;
              }
              Cookies.set("pin", pin!);
            }
            history.replace(`/${uuid}`);
          }}
        >
          {t("Go to test page")}
        </Button>
      </div>
    </div>
  );
};

const checkUuid = (text: string) =>
  /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/i.test(text);

interface UuidInputActions {
  validateAndGetUuid: () => string | null;
}

const UuidInput: FC<{
  actionsRef?: MutableRefObject<UuidInputActions | undefined>;
}> = ({ actionsRef }) => {
  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  const [hasError, setHasError] = useState(false);

  if (actionsRef) {
    actionsRef.current = {
      validateAndGetUuid: () => {
        const text = inputRefs.map((ref) => `${ref.current?.value}`).join("-");
        if (!checkUuid(text)) {
          setHasError(true);
          return null;
        }
        setHasError(false);
        return text;
      },
    };
  }

  const getOnInput =
    (maxChars: number, nextInputRef?: RefObject<HTMLInputElement>) =>
    (event: React.FormEvent<HTMLInputElement>) => {
      setHasError(false);
      event.currentTarget.value = event.currentTarget.value
        .toLowerCase()
        .replace(/[^0-9abcdef]/g, "")
        .slice(0, maxChars);
      if (event.currentTarget.value.length === maxChars) {
        nextInputRef?.current?.focus();
      }
    };

  return (
    <div className="uuid-input">
      <input
        className={`w8${hasError ? " error" : ""}`}
        type="text"
        ref={inputRefs[0]}
        onInput={getOnInput(8, inputRefs[1])}
      />
      <span> - </span>
      <input
        className={`w4${hasError ? " error" : ""}`}
        type="text"
        ref={inputRefs[1]}
        onInput={getOnInput(4, inputRefs[2])}
      />
      <span> - </span>
      <input
        className={`w4${hasError ? " error" : ""}`}
        type="text"
        ref={inputRefs[2]}
        onInput={getOnInput(4, inputRefs[3])}
      />
      <span> - </span>
      <input
        className={`w4${hasError ? " error" : ""}`}
        type="text"
        ref={inputRefs[3]}
        onInput={getOnInput(4, inputRefs[4])}
      />
      <span> - </span>
      <input
        className={`w12${hasError ? " error" : ""}`}
        type="text"
        ref={inputRefs[4]}
        onInput={getOnInput(12)}
      />
    </div>
  );
};

interface PinInputActions {
  validateAndGetPin: () => string | null;
}

const PinInput: FC<{
  actionsRef?: MutableRefObject<PinInputActions | undefined>;
}> = ({ actionsRef }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [hasError, setHasError] = useState(false);

  if (actionsRef) {
    actionsRef.current = {
      validateAndGetPin: () => {
        const text = `${inputRef?.current?.value}`;
        if (!/^\d{4}$/.test(text)) {
          setHasError(true);
          return null;
        }
        setHasError(false);
        return text;
      },
    };
  }

  return (
    <div className="pin-input">
      <input
        className={`w4${hasError ? " error" : ""}`}
        type="text"
        ref={inputRef}
        onInput={(event) => {
          setHasError(false);
          event.currentTarget.value = event.currentTarget.value
            .toLowerCase()
            .replace(/[^\d]/g, "")
            .slice(0, 4);
        }}
      />
    </div>
  );
};

export default Tc;
