import { Button } from "antd";
import Cookies from "js-cookie";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div id="not-found">
      <div className="heading">{t("Not Found")}</div>
      {Cookies.get("tcks") == null ? (
        <>
          <div className="hint">{t("NotFoundHint")}</div>
          <a className="logout" href="/logout">
            {t("Logout")}
          </a>
        </>
      ) : (
        <div className="tc-content">
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              history.replace(`/tc${Cookies.get("pin") != null ? "?pin" : ""}`);
            }}
          >
            {t("Back to test ID input")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default NotFound;
