import NotFound from "$components/NotFound";
import TrialAborted from "$components/TrialAborted";
import TrialEnded from "$components/TrialEnded";
import { TrialInfo } from "$types/TrialInfo";
import { useQuery } from "@tanstack/react-query";
import React, { createContext, useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import "./utils/global";
import AppLayout from "./AppLayout";

interface AppContext {
  trialId: string;
  trialInfo: TrialInfo;
  refetchTrialInfo: () => Promise<void>;
}

const AppContext = createContext<AppContext | undefined>(undefined);

export const useApp = (): AppContext => {
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw Error("useApp cannot be used outside AppContext.Provider");
  }
  return appContext;
};

const InnerApp = () => {
  const {
    params: { id: trialId },
  } = useRouteMatch<{ id: string }>();

  const {
    data: trialInfo,
    error: trialInfoError,
    refetch: refetchTrialInfo,
  } = useQuery<TrialInfo>([`/api/trial/${trialId}`]);

  return trialInfoError ? (
    <NotFound />
  ) : trialInfo == null ? (
    <></>
  ) : !trialInfo.confirmed ? (
    <NotFound />
  ) : trialInfo.aborted ? (
    <TrialAborted />
  ) : (
    <AppContext.Provider
      value={{
        trialId,
        trialInfo,
        refetchTrialInfo: async () => {
          await refetchTrialInfo();
        },
      }}
    >
      {trialInfo.remainingMills != null && trialInfo.remainingMills <= 0 ? (
        <TrialEnded />
      ) : (
        <AppLayout />
      )}
    </AppContext.Provider>
  );
};

export default InnerApp;
