import Chat, { ChatActions } from "$components/Chat";
import Video, { VideoActions } from "$components/Video";
import { PostFinishTrialResponse } from "$types/PostFinishTrialResponse";
import { PostStartTrialResponse } from "$types/PostStartTrialResponse";
import { TrialStatus } from "$types/TrialStatus";
import { auth, db } from "$utils/firebase";
import {
  ClockCircleOutlined,
  FormOutlined,
  MessageOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Badge, Button, message, Popconfirm, Tooltip } from "antd";
import { onIdTokenChanged, signInWithCustomToken } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Notepad, { NotepadActions } from "./components/Notepad";
import { useApp } from "./InnerApp";

const AppLayout = () => {
  const { t } = useTranslation();

  const { trialId, trialInfo, refetchTrialInfo } = useApp();

  const isOnTrial =
    trialInfo.remainingMills != null && trialInfo.remainingMills > 0;

  const [fbSignedIn, setFbSignedIn] = useState(false);

  const { data: fbToken } = useQuery<string>([`/api/fbtoken/${trialId}`]);

  useEffect(() => {
    if (fbToken == null) {
      return;
    }
    signInWithCustomToken(auth, fbToken);
  }, [fbToken]);

  useEffect(() => {
    onIdTokenChanged(auth, async (user) => {
      if (user) {
        const result = await user.getIdTokenResult();
        if (result.claims.trialId === trialId) {
          setFbSignedIn(true);
          return;
        }
      }
      setFbSignedIn(false);
    });
  }, []);

  const [trialStatus, setTrialStatus] = useState<TrialStatus | undefined>();

  useEffect(() => {
    if (!fbSignedIn) {
      return;
    }
    const unsubscribe = onSnapshot(doc(db, "trials", trialId), (snapshot) => {
      setTrialStatus(snapshot.data() as TrialStatus);
    });
    return unsubscribe;
  }, [fbSignedIn]);

  const [trialSignature, setTrialSignature] = useState(() =>
    localStorage.getItem(`trialSignature:${trialId}`)
  );

  useEffect(() => {
    if (trialSignature == null) {
      return;
    }
    localStorage.setItem(`trialSignature:${trialId}`, trialSignature);
  }, [trialSignature]);

  const contentRef = useRef<HTMLIFrameElement>(null);
  const [isContentLoading, setIsContentLoading] = useState(false);

  useEffect(() => {
    const element = contentRef.current;
    if (element == null) {
      return;
    }

    if (trialSignature != null) {
      Cookies.set("trialSignature", trialSignature);
    }
    const params = new URLSearchParams();
    if (location.search) {
      params.set("examQuery", new URLSearchParams(location.search).toString());
    }
    element.src = `/${trialInfo.module}/${trialId}${
      params.toString() ? `?${params.toString()}` : ""
    }`;
    setIsContentLoading(true);
  }, [trialSignature]);

  const { mutateAsync: startTrial, isLoading: isTrialStarting } =
    useMutation<PostStartTrialResponse>({
      mutationFn: async () => {
        const res = await fetch(`/api/start/${trialId}`, {
          method: "POST",
          cache: "no-cache",
        });
        const body = await res.json();
        if ("error" in body) {
          throw Error(body.error.message);
        }
        if (!res.ok) {
          throw Error("fail to start");
        }
        return body;
      },
      onSuccess: (data) => {
        setTrialSignature(data.signature);
        refetchTrialInfo();
      },
    });

  const { mutateAsync: finishTrial, isLoading: isTrialFinishing } =
    useMutation<PostFinishTrialResponse>({
      mutationFn: async () => {
        const res = await fetch(`/api/finish/${trialId}`, {
          method: "POST",
          cache: "no-cache",
        });
        if (!res.ok) {
          throw Error("fail to finish");
        }
        return res.json();
      },
      onSuccess: () => {
        refetchTrialInfo();
      },
    });

  useEffect(() => {
    if (!isOnTrial) {
      return;
    }
    const func = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = "";
    };
    window.addEventListener("beforeunload", func);
    return () => {
      window.removeEventListener("beforeunload", func);
    };
  }, [isOnTrial]);

  const [isCountdownVisible, setIsCountdownVisible] = useState(true);

  const [countdown, setCountdown] = useState<string>();

  useEffect(() => {
    if (!trialInfo.remainingMills) {
      return;
    }

    const endAtMills = new Date().getTime() + trialInfo.remainingMills;

    const id = window.setInterval(() => {
      const remainingMills = endAtMills - new Date().getTime();
      if (remainingMills <= 0) {
        window.clearInterval(id);
        refetchTrialInfo();
        return;
      }

      const hours = Math.floor(remainingMills / (60 * 60 * 1000));
      const mins = Math.floor((remainingMills / (60 * 1000)) % 60);
      const secs = Math.floor((remainingMills / 1000) % 60);
      setCountdown(
        [hours, mins, secs].map((v) => String(v).padStart(2, "0")).join(":")
      );
    }, 500);

    return () => {
      window.clearInterval(id);
    };
  }, [trialInfo.remainingMills]);

  useEffect(() => {
    if (trialInfo.millsToOpen == null) {
      return;
    }
    const id = window.setTimeout(() => {
      refetchTrialInfo();
    }, Math.min(trialInfo.millsToOpen, 0x7fffffff));
    return () => {
      clearTimeout(id);
    };
  }, [trialInfo.millsToOpen]);

  useEffect(() => {
    if (trialInfo.millsToClose == null) {
      return;
    }
    const id = window.setTimeout(() => {
      refetchTrialInfo();
    }, Math.min(trialInfo.millsToClose, 0x7fffffff));
    return () => {
      clearTimeout(id);
    };
  }, [trialInfo.millsToClose]);

  const chatActionsRef = useRef<ChatActions>();
  const notepadActionsRef = useRef<NotepadActions>();

  const [isVideoConnected, setVideoConnected] = useState(false);
  const [isVideoPublishing, setVideoPublishing] = useState(false);
  const videoActionsRef = useRef<VideoActions>();

  useEffect(() => {
    if (!trialInfo.useSupervision || !trialStatus?.ready) {
      return;
    }
    videoActionsRef.current?.setVideoVisible(false);
    videoActionsRef.current?.start();
  }, [trialStatus?.ready]);

  const signal = (type: string, value: any) => {
    videoActionsRef.current?.session?.signal(
      {
        type,
        data: JSON.stringify({
          trialId,
          type,
          value,
        }),
      },
      (error) => {}
    );
  };

  useEffect(() => {
    if (!trialInfo.useSupervision) {
      return;
    }
    signal("isOnTrialChanged", isOnTrial);

    if (!isOnTrial) {
      return;
    }
    videoActionsRef.current?.setVideoVisible(false);
    videoActionsRef.current?.start();

    return () => {
      signal("isOnTrialChanged", null);
    };
  }, [isOnTrial]);

  const [isVideoVisible, setVideoVisible] = useState<boolean>();

  const [lock, setLock] = useState(false);

  useEffect(() => {
    interface SetLockMessage {
      type: "setLock";
      value: boolean;
    }
    interface getTrialIdMessage {
      type: "getTrialId";
    }
    type Message = SetLockMessage | getTrialIdMessage;

    window.addEventListener(
      "message",
      (event: MessageEvent) => {
        if (
          !(
            event.source instanceof MessagePort ||
            event.source instanceof ServiceWorker
          ) &&
          event.source?.top === window
        ) {
          const message = event.data as Message;
          if (message.type === "setLock") {
            setLock(message.value);
          }
          if (message.type === "getTrialId") {
            event.source.postMessage(
              {
                type: "getTrialIdCallback",
                trialId,
              },
              "*"
            );
          }
        }
      },
      { once: false }
    );
  }, []);

  return (
    <div id="app-layout">
      <header id="header-nav">
        <div className="left">{trialInfo.headerTitle}</div>
        <div className="center">
          {isOnTrial && countdown != null ? (
            <div className="timer">
              <Tooltip
                title={isCountdownVisible ? t("Hide time") : t("Show time")}
              >
                <ClockCircleOutlined
                  className="icon"
                  onClick={() => {
                    setIsCountdownVisible((visible) => !visible);
                  }}
                />
              </Tooltip>
              {isCountdownVisible ? (
                <Tooltip title={t("Time remaining")}>
                  <span className="content">{countdown}</span>
                </Tooltip>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="right">
          {trialInfo.useSupervision && trialInfo.enableChat ? (
            <Tooltip title={t("Call Proctor")}>
              <Button
                className="item"
                shape="circle"
                icon={<MessageOutlined />}
                style={{ WebkitUserSelect: "none" }}
                onClick={() => {
                  chatActionsRef.current?.setChatVisible((visible) => !visible);
                }}
              />
            </Tooltip>
          ) : null}
          {trialInfo.useSupervision &&
          (trialInfo.enableCamera ||
            trialInfo.enableMicrophone ||
            trialInfo.enableScreenShare) ? (
            <Button
              className="item"
              type={isOnTrial || trialStatus?.ready ? "default" : "primary"}
              shape={isOnTrial || trialStatus?.ready ? "circle" : "round"}
              disabled={
                trialStatus?.opentokSessionId == null ||
                (!trialInfo.isOpen && !isOnTrial) ||
                (!trialStatus.ready &&
                  (lock || isContentLoading || (isVideoVisible && !isOnTrial)))
              }
              style={{ WebkitUserSelect: "none" }}
              icon={
                <Badge
                  dot
                  status={
                    isVideoConnected && isVideoPublishing
                      ? "success"
                      : undefined
                  }
                  count={isVideoConnected && isVideoPublishing ? undefined : 0}
                >
                  <VideoCameraOutlined />
                </Badge>
              }
              onClick={() => {
                videoActionsRef.current?.setVideoVisible((visible) => !visible);
                videoActionsRef.current?.start();
              }}
            >
              {isOnTrial || trialStatus?.ready
                ? null
                : t("Request to start exam")}
            </Button>
          ) : null}
          {isOnTrial && trialInfo.enableNotepad ? (
            <Button
              className="item"
              shape="round"
              icon={<FormOutlined />}
              style={{ WebkitUserSelect: "none" }}
              onClick={() => {
                notepadActionsRef.current?.setNotepadVisible((v) => !v);
              }}
            >
              {t("Notepad")}
            </Button>
          ) : null}
          {!isOnTrial ? (
            <Button
              className="item"
              type="primary"
              shape="round"
              style={{ WebkitUserSelect: "none" }}
              disabled={
                lock ||
                isContentLoading ||
                !trialStatus?.ready ||
                !trialInfo.isOpen
              }
              loading={isTrialStarting}
              onClick={async () => {
                try {
                  await startTrial();
                } catch (e: any) {
                  message.error(e.message);
                  return;
                }
                chatActionsRef.current?.setChatVisible(false);
              }}
            >
              {t("Start Exam")}
            </Button>
          ) : (
            <Popconfirm
              title={t("Do you really finish this exam?")}
              okText={t("Finish")}
              cancelText={t("Not now")}
              disabled={lock || trialSignature == null}
              okButtonProps={{ loading: isTrialFinishing }}
              onConfirm={() => {
                finishTrial();
              }}
            >
              <Button
                className="item"
                type="default"
                shape="round"
                disabled={lock || trialSignature == null}
                loading={isTrialFinishing}
              >
                {t("Finish Exam")}
              </Button>
            </Popconfirm>
          )}
        </div>
      </header>
      <Video
        trialId={trialId}
        sessionId={trialStatus?.opentokSessionId}
        enableCamera={trialInfo.enableCamera}
        enableMicrophone={trialInfo.enableMicrophone}
        enableScreenShare={trialInfo.enableScreenShare}
        autoInitialScreenShare={true}
        actionsRef={videoActionsRef}
        incomingPlaceholder={
          !(isOnTrial || trialStatus?.ready) ? t("Please wait a proctor") : ""
        }
        onConnectionChanged={(connected) => {
          setVideoConnected(connected);
        }}
        onPublishersChanged={(publishers) => {
          setVideoPublishing(publishers.length > 0);
        }}
        onVisibilityChanged={setVideoVisible}
        onSignal={(event) => {
          if (event.type === "trialUpdatedByStaff") {
            refetchTrialInfo();
          }
        }}
      />
      <Chat
        trialId={trialId}
        activated={fbSignedIn}
        actionsRef={chatActionsRef}
        onMessage={(messages) => {
          if (messages.length === 0) {
            return;
          }
          chatActionsRef.current?.setChatVisible(true);
        }}
      />
      {trialInfo.enableNotepad ? (
        <Notepad trialId={trialId} actionsRef={notepadActionsRef} />
      ) : null}
      {isOnTrial && trialSignature == null ? (
        <p style={{ color: "red" }}>
          {t("Exam can be taken by only one browser")}
        </p>
      ) : (
        <iframe
          className={`content ${isOnTrial ? "on-trial" : "out-of-trial"}`}
          ref={contentRef}
          onLoad={() => setIsContentLoading(false)}
          title="content"
        />
      )}
    </div>
  );
};

export default AppLayout;
