import {
  AudioOutlined,
  DesktopOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import OT from "@opentok/client";
import { useQuery } from "@tanstack/react-query";
import { Button, message, Progress, Select } from "antd";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";

export interface VideoActions {
  setVideoVisible: Dispatch<SetStateAction<boolean>>;
  attachScreenShare: Dispatch<SetStateAction<boolean>>;
  start: () => void;
  session?: OT.Session;
}

interface Props {
  trialId: string;
  sessionId?: string;
  actionsRef?: MutableRefObject<VideoActions | undefined>;
  enableCamera: boolean;
  enableMicrophone: boolean;
  enableScreenShare: boolean;
  initialVisibility?: boolean;
  incomingPlaceholder?: string;
  autoInitialScreenShare?: boolean;
  onConnectionChanged?: (connected: boolean) => void;
  onPublishersChanged?: (publishers: OT.Publisher[]) => void;
  onVisibilityChanged?: (visible: boolean) => void;
  onSignal?: (event: { type: string; data?: any }) => void;
}

const Video = ({
  trialId,
  sessionId,
  actionsRef,
  enableCamera,
  enableMicrophone,
  enableScreenShare,
  initialVisibility = false,
  incomingPlaceholder = "",
  autoInitialScreenShare = false,
  onConnectionChanged,
  onPublishersChanged,
  onVisibilityChanged,
  onSignal,
}: Props) => {
  const { t } = useTranslation();

  const [isVideoVisible, setVideoVisible] = useState(initialVisibility);

  useEffect(() => {
    onVisibilityChanged?.(isVideoVisible);
  }, [isVideoVisible]);

  const [started, setStarted] = useState(false);

  const [cameraPublisher, setCameraPublisher] = useState<OT.Publisher>();

  const [streamAudioSource, setStreamAudioSource] =
    useState<MediaStreamTrack>();

  const [streamVideoDeviceId, setStreamVideoDeviceId] = useState<string>();

  const [streamingPublishers, setStreamingPublishers] = useState<{
    [streamId: string]: OT.Publisher;
  }>({});

  const addStreamingPublisherListener = (publisher: OT.Publisher) => {
    publisher.on("streamCreated", (event) => {
      setStreamingPublishers((publishers) => {
        const _publishers = {
          ...publishers,
          [event.stream.streamId]: publisher,
        };
        window.setTimeout(() => {
          onPublishersChanged?.(Object.values(_publishers));
        });
        return _publishers;
      });
    });
    publisher.on("streamDestroyed", (event) => {
      setStreamingPublishers(({ ...publishers }) => {
        delete publishers[event.stream.streamId];
        window.setTimeout(() => {
          onPublishersChanged?.(Object.values(publishers));
        });
        return publishers;
      });
    });
  };

  const [cameraPublishAttempted, setCameraPublishAttempted] = useState(false);

  useEffect(() => {
    if (!(enableCamera || enableMicrophone) || !started) {
      return;
    }
    const publisher = OT.initPublisher(
      "camera-preview",
      {
        insertMode: "append",
        showControls: false,
        publishVideo: enableCamera,
        publishAudio: enableMicrophone,
        width: "100%",
        height: "100%",
        name: JSON.stringify({ kind: "user", trialId }),
        resolution: "1280x720",
        frameRate: 7,
      },
      (error) => {
        if (error) {
          message.error(t("Fail to preview camera"));
          return;
        }
      }
    );
    publisher.on("accessAllowed", () => {
      window.setTimeout(() => {
        updateDevices();
        setStreamAudioSource(publisher.getAudioSource());
        setStreamVideoDeviceId(
          publisher.getVideoSource().deviceId ?? undefined
        );
      }); // publisher does not returns deviceId without delay
      setCameraPublisher(publisher);
    });
    publisher.on("accessDenied", () => {
      message.error(t("Fail to access devices"));
      setCameraPublishAttempted(true);
    });
    addStreamingPublisherListener(publisher);
  }, [started]);

  const { data: otToken, isLoading: otTokenLoading } = useQuery<string>(
    [`/api/ottoken/${trialId}`],
    {
      enabled: sessionId != null,
    }
  );

  const [session, setSession] = useState<OT.Session>();

  useEffect(() => {
    if (sessionId == null) {
      return;
    }
    const _session = OT.initSession(process.env.OPENTOK_APIKEY!, sessionId);
    setSession(_session);
    return () => {
      _session.disconnect();
    };
  }, [sessionId]);

  const [isConnected, setIsConnected] = useState(false);
  const [onceConnected, setOnceConnected] = useState(false);

  useEffect(() => {
    if (session == null || otToken == null || otTokenLoading) {
      return;
    }
    session.on("sessionConnected", () => {
      setOnceConnected(true);
      setIsConnected(true);
      onConnectionChanged?.(true);
    });
    session.on("sessionDisconnected", () => {
      setIsConnected(false);
      onConnectionChanged?.(false);
    });
    session.on("sessionReconnected", () => {
      setIsConnected(true);
      onConnectionChanged?.(true);
    });
    session.on("streamCreated", (event) => {
      session.subscribe(
        event.stream,
        "video-incoming",
        {
          insertMode: "append",
          showControls: false,
          width: "100%",
          height: "100%",
        },
        (error) => {
          if (error) {
            message.error(t("Fail to subscribe video"));
            return;
          }
        }
      );
    });
    session.on("signal", (event) => {
      onSignal?.({
        type: event.type.split(":")[1],
        ...(event.data && { data: JSON.parse(event.data) }),
      });
    });
    session.connect(otToken, (error) => {
      if (error) {
        message.error(t("Fail to connect to video session"));
        return;
      }
      if (session.capabilities.publish !== 1) {
        message.error(t("audio-video stream cannot be published."));
        return;
      }
    });
  }, [session, otToken]);

  const onceScreenAttached = useRef(false);

  useEffect(() => {
    if ((enableCamera || enableMicrophone) && !cameraPublishAttempted) {
      return;
    }
    if (!onceConnected || !started || onceScreenAttached.current) {
      return;
    }
    if (enableScreenShare && autoInitialScreenShare) {
      attachScreenShare();
    }
  }, [onceConnected, started, cameraPublishAttempted]);

  const attachScreenShare = () => {
    if (!session) {
      return;
    }
    const publisher = OT.initPublisher(
      "screen-preview",
      {
        insertMode: "append",
        showControls: false,
        videoSource: "screen",
        width: "100%",
        height: "100%",
        name: JSON.stringify({ kind: "user", trialId }),
        resolution: "1280x720",
        frameRate: 7,
      },
      (error) => {
        if (error) {
          message.error(t("Fail to preview screen"));
          return;
        }
        session.publish(publisher, (err) => {
          if (err) {
            message.error(t("Fail to publish screen"));
            return;
          }
        });
      }
    );
    addStreamingPublisherListener(publisher);
    onceScreenAttached.current = true;
  };

  useEffect(() => {
    if (cameraPublisher == null || session == null || !isConnected) {
      return;
    }
    session.publish(cameraPublisher, (error) => {
      if (error) {
        message.error(t("Fail to publish video"));
      }
      setCameraPublishAttempted(true);
    });
    return () => {
      session.unpublish(cameraPublisher);
    };
  }, [cameraPublisher, session, isConnected]);

  const [mediaDevices, setMediaDevices] = useState<MediaDeviceInfo[]>();

  const selectedAudioDeviceId = !streamAudioSource
    ? undefined
    : mediaDevices?.find((device) => device.label === streamAudioSource.label)
        ?.deviceId;

  const updateDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    setMediaDevices(devices);
    return devices;
  };

  useEffect(() => {
    updateDevices();
    navigator.mediaDevices.ondevicechange = () => {
      updateDevices();
    };
  }, []);

  if (actionsRef != null) {
    actionsRef.current = {
      setVideoVisible,
      attachScreenShare,
      start: () => setStarted(true),
      session,
    };
  }

  return (
    <div className="video-container">
      <Draggable handle=".handle" bounds="parent">
        <div className="video-window" hidden={!isVideoVisible}>
          <div className="header-bar handle">
            <span>{t("Video Session")}</span>
          </div>
          <div className="videos">
            <div className="video-previews">
              <div id="camera-preview" className="video-preview" />
              <div id="screen-preview" className="video-preview" />
            </div>
            <div id="video-incoming">
              <div className="placeholder">
                <div>{incomingPlaceholder}</div>
              </div>
            </div>
          </div>

          <div className="controller-container">
            <div className="labeled-controller">
              <VideoCameraOutlined className="label" />
              <span className="label">{t("Camera")}</span>
              <Select
                disabled={!enableCamera || mediaDevices == null}
                className="controller"
                dropdownMatchSelectWidth={false}
                value={streamVideoDeviceId}
                onSelect={async (value) => {
                  if (
                    value === streamVideoDeviceId ||
                    cameraPublisher == null
                  ) {
                    return;
                  }
                  await cameraPublisher.setVideoSource(value);
                  setStreamVideoDeviceId(
                    cameraPublisher.getVideoSource().deviceId ?? undefined
                  );
                }}
              >
                {mediaDevices
                  ?.filter((device) => device.kind === "videoinput")
                  .map((device) => (
                    <Select.Option
                      key={`${device.deviceId}-${device.kind}`}
                      value={device.deviceId}
                    >
                      {device.label}
                    </Select.Option>
                  ))}
              </Select>
            </div>

            <div className="labeled-controller">
              {cameraPublisher == null ? null : (
                <span className="label">
                  <AudioLevelIndicator publisher={cameraPublisher} />
                </span>
              )}
              <AudioOutlined className="label" />
              <span className="label">{t("Microphone")}</span>
              <Select
                disabled={!enableMicrophone || mediaDevices == null}
                className="controller"
                dropdownMatchSelectWidth={false}
                value={selectedAudioDeviceId}
                onSelect={async (value) => {
                  if (
                    value === selectedAudioDeviceId ||
                    cameraPublisher == null
                  ) {
                    return;
                  }
                  await cameraPublisher.setAudioSource(value);
                  setStreamAudioSource(cameraPublisher.getAudioSource());
                }}
              >
                {mediaDevices
                  ?.filter((device) => device.kind === "audioinput")
                  .map((device) => (
                    <Select.Option
                      key={`${device.deviceId}-${device.kind}`}
                      value={device.deviceId}
                    >
                      {device.label}
                    </Select.Option>
                  ))}
              </Select>
            </div>

            <div className="buttons">
              <Button
                className="controller"
                disabled={!isConnected || !enableScreenShare}
                icon={<DesktopOutlined />}
                shape="round"
                onClick={() => {
                  attachScreenShare();
                }}
              >
                {t("Add Shared Screen")}
              </Button>
            </div>
          </div>
        </div>
      </Draggable>
    </div>
  );
};

const AudioLevelIndicator = ({ publisher }: { publisher: OT.Publisher }) => {
  const [level, setLevel] = useState(0);

  useEffect(() => {
    let movingAvg = 0;
    const callback = ({ audioLevel }: { audioLevel: number }) => {
      movingAvg = movingAvg * 0.5 + 0.5 * audioLevel;
      const lev = Math.log(movingAvg) / Math.LN10 / 2.0 + 1;
      setLevel(Math.round(Math.min(Math.max(lev, 0), 1) * 10));
    };
    publisher.on("audioLevelUpdated", callback);
    return () => {
      publisher.off("audioLevelUpdated", callback);
    };
  }, [publisher]);

  return (
    <Progress percent={level * 10} showInfo={false} steps={10} size="small" />
  );
};

export default Video;
