import { CloseOutlined } from "@ant-design/icons";
import { Input, message, Tooltip } from "antd";
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";

export interface NotepadActions {
  setNotepadVisible: Dispatch<SetStateAction<boolean>>;
}

interface Props {
  trialId: string;
  actionsRef?: MutableRefObject<NotepadActions | undefined>;
}

const Notepad = ({ trialId, actionsRef }: Props) => {
  const { t } = useTranslation();

  const [isNotepadVisible, setNotepadVisible] = useState<boolean>(false);

  const [text, setText] = useState<string>(
    localStorage.getItem(`notepad:${trialId}`) ?? ""
  );
  useEffect(() => {
    localStorage.setItem(`notepad:${trialId}`, text);
  }, [text]);

  useEffect(() => {
    if (actionsRef == null) {
      return;
    }
    actionsRef.current = {
      setNotepadVisible,
    };
  }, [actionsRef]);

  return (
    <div className="notepad-container">
      <Draggable handle=".handle" bounds="parent">
        <div
          className="notepad-window"
          style={{ visibility: isNotepadVisible ? "visible" : "hidden" }}
        >
          <div className="header-bar handle">
            <span>{t("Notepad")}</span>
            <Tooltip title={t("Close")}>
              <CloseOutlined
                className="close"
                style={{ display: "inline", cursor: "pointer" }}
                onClick={() => {
                  setNotepadVisible(false);
                }}
                onTouchEnd={() => {
                  setNotepadVisible(false);
                }}
              />
            </Tooltip>
          </div>
          <div className="content">
            <Input.TextArea
              className="text"
              autoSize={{ minRows: 10, maxRows: 10 }}
              placeholder={t("Take a note")}
              value={text}
              onChange={({ target: { value } }) => {
                setText(value);
              }}
              onCopy={(event) => {
                event.preventDefault();
                message.warn(t("Copy is prohibited"));
              }}
              onCut={(event) => {
                event.preventDefault();
                message.warn(t("Cut is prohibited"));
              }}
              onPaste={(event) => {
                event.preventDefault();
                message.warn(t("Paste is prohibited"));
              }}
            />
          </div>
        </div>
      </Draggable>
    </div>
  );
};

export default Notepad;
