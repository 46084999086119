import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// @ts-ignore
import en from "../locales/en.yml";
// @ts-ignore
import ja from "../locales/ja.yml";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: en,
      },
      ja: {
        translations: ja,
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
    fallbackLng: "en",
  });
