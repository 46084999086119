import React from "react";
import { useTranslation } from "react-i18next";
import urljoin from "url-join";
import { useApp } from "../InnerApp";
import InstantDisclosure from "./InstantDisclosure";

const TrialEnded = () => {
  const { t } = useTranslation();

  const { trialId, trialInfo } = useApp();

  return (
    <div id="trial-finished">
      <div className="heading">{t("This exam is over")}</div>
      <div className="body">
        {trialInfo.hasSub && !trialInfo.requireTcKey ? (
          <>
            {t("finishedPreMessageWithSub")}
            <a href={urljoin(process.env.SITE_URL!, "/achievements")}>
              {t("achievements page")}
            </a>
            {t("finishedPostMessageWithSub")}
          </>
        ) : trialInfo.hasSub && trialInfo.requireTcKey ? (
          <>
            {t("finishedPreMessageWithSub")}
            {t("achievements page")}
            {t("finishedPostMessageWithSub")}
          </>
        ) : (
          <>
            <p>{t("finishedMessageWithoutSub")}</p>
            <p style={{ color: "gray" }}>{t("finishedNoteWithoutSub")}</p>
          </>
        )}
      </div>
      {trialInfo.instantDisclosure ? (
        <div className="disclosure">
          <InstantDisclosure trialId={trialId} />
        </div>
      ) : null}
    </div>
  );
};

export default TrialEnded;
